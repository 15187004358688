import React from "react"
import { Link } from 'gatsby';
import RichText from './richText';
import PropTypes from "prop-types";
import {
      Formik,
      Form,
      Field,
      ErrorMessage
} from 'formik'
import { TranslateResolver } from "../utils/translateResolver";
import { LangResolver } from '../utils/langResolver'
import { useLocation } from "@reach/router"



const Footer = ({ data, navData, actualLang, showCta }) => {

      var lang = LangResolver();

      const { pathname } = useLocation()

      const nombreError = TranslateResolver(5);
      const emailError = TranslateResolver(6);

      const $cta = data.body[0];
      const $datosContacto = data.body[1];
      const $redesSociales = data.body[2];
      const $partners = data.body[3];
      const $form = data.body[4];

      const encode = (data) => {
            return Object.keys(data)
                  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
                  .join("&");
      }

      //Form

      var loading = false;
      var messageSent = false;
      var messageResult = false;




      return (


            <>

                  {$cta && !showCta &&

                        <div className="c-start-cta js-header-waypoint" data-logo="light" data-nav="light">
                              <div className="c-start-cta__fields"></div>
                              <div className="c-start-cta__wrapper o-wrapper o-align-middle">
                                    <div className="o-align-middle__aligner">

                                          <p className="c-start-cta__text c-heading-group__title">{$cta.primary.title[0].text}</p>
                                          {$cta.primary.link &&
                                                <a href="#contact" className="c-start-cta__btn c-rounded-btn c-rounded-btn--invert js-show-contact"><span>{$cta.primary.link[0].text}</span></a>
                                          }

                                    </div>
                              </div>
                        </div>
                  }


                  <footer className="c-footer js-header-waypoint" data-logo="dark" data-nav="light">
                        <div className="c-footer__top">
                              <div className="c-footer__top-wrapper o-wrapper">

                                    <section className="c-footer__newsletter c-newsletter">
                                          <Formik
                                                initialValues={{
                                                      nombre: '',
                                                      email: ''
                                                }}
                                                onSubmit={
                                                      (values, actions) => {

                                                            loading = true;

                                                            fetch("/", {
                                                                  method: "POST",
                                                                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                                                                  body: encode({ "form-name": "newsletter", ...values })
                                                            })
                                                                  .then(() => {
                                                                        loading = false;
                                                                        messageSent = true;
                                                                        messageResult = true;


                                                                        var tracker = window.ga.getAll()[0];
                                                                        tracker.send('event', 'BotonSuscribirme', 'Click', 'Footer');

                                                      
                                                                        actions.resetForm()


                                                                  })
                                                                  .catch(() => {
                                                                        loading = false;
                                                                        messageSent = true;

                                                                        messageResult = false;
                                                                  })
                                                                  .finally(() => {

                                                                        actions.setSubmitting(false)

                                                                        messageSent = false;


                                                                  })
                                                      }
                                                }
                                                validate={values => {
                                                      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                                      const errors = {};
                                                      if (!values.nombre) {
                                                            errors.nombre = nombreError
                                                      }
                                                      if (!values.email || !emailRegex.test(values.email)) {
                                                            errors.email = emailError
                                                      }

                                                      return errors;
                                                }}
                                          >
                                                {() => (
                                                      <Form key="newsletter" name="newsletter" method="POST" data-netlify="true" netlify-honeypot="bot-field" className="c-newsletter__form">

                                                            <p className="u-hidden">
                                                                  <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                                                            </p>


                                                            <input type="hidden" name="form-name" value="newsletter" />

                                                            {$form.primary && <h2 className="c-newsletter__title c-heading-group__title">{$form.primary.title[0].text}</h2>}


                                                            {$form.fields &&
                                                                  $form.fields.map((field, i) => {
                                                                        return (
                                                                              <>


                                                                                    <label key={i} className={`c-newsletter__input-holder`}>
                                                                                          <span className="c-newsletter__label">{field.label}</span>

                                                                                          <Field name={field.name} type={field.type} className="c-newsletter__input" placeholder={field.placeholder} />

                                                                                    </label>

                                                                                    <label for={field.name} className="c-newsletter__error"><ErrorMessage name={field.name} /></label>
                                                                              </>

                                                                        )
                                                                  })}

                                                            <div data-netlify-recaptcha="true"></div>

                                                            <div className="c-newsletter__bottom">



                                                                  <div>
                                                                        {$form.primary && !loading && <button type="submit" className="c-newsletter__submit c-rounded-btn"><span>{$form.primary.button_text}</span></button>}
                                                                        {loading && <button type="button" className="c-newsletter__submit c-rounded-btn is-loading" disabled><span>{TranslateResolver(10)}</span></button>}

                                                                        {!loading && messageSent && <div className="c-newsletter__response o-align-middle" data-success={messageResult}>

                                                                              {messageResult &&


                                                                                    <span className="o-align-middle__aligner">{TranslateResolver(11)}</span>}

                                                                              {!messageResult &&
                                                                                    <span className="o-align-middle__aligner">{TranslateResolver(12)}</span>}

                                                                        </div>
                                                                        }

                                                                  </div>


                                                            </div>

                                                      </Form>
                                                )}




                                          </Formik>



                                    </section>


                                    <nav className="c-footer__nav c-footer-nav">

                                          {navData.title &&
                                                <h2 className="c-footer-nav__title c-heading-group__kicker">{navData.title[0].text}</h2>
                                          }

                                          <ul className="c-footer-nav__list">


                                                {navData.links.map((field, i) => {
                                                      return (

                                                            <>

                                                                  <>

                                                                        <li key={field.link._meta.uid} className="c-footer-nav__item">



                                                                              {(pathname + "home" == "/" + field.link._meta.uid || pathname + "home" == "/" + field.link._meta.uid + "/") &&
                                                                                    <button className="c-footer-nav__link js-goTo-intro">
                                                                                          <span>{field.label}</span>
                                                                                    </button>
                                                                              }

                                                                              {(pathname == "/" + field.link._meta.uid || pathname == "/" + field.link._meta.uid + "/") &&
                                                                                    <button className="c-footer-nav__link js-goTo-intro"><span>{field.label}</span></button>
                                                                              }

                                                                              {field && field.link._meta.uid && field.link._meta.uid == "home" && (pathname + "home" != "/" + field.link._meta.uid && pathname + "home" != "/" + field.link._meta.uid + "/") &&

                                                                                    <Link to={`${lang}`} className="c-footer-nav__link">{field.label}</Link>
                                                                              }

                                                                              {field && field.link._meta.uid && field.link._meta.uid != "home" && (pathname != "/" + field.link._meta.uid && pathname != "/" + field.link._meta.uid + "/") &&

                                                                                    <Link to={`${lang}${field.link._meta.uid}`} className="c-footer-nav__link">{field.label}</Link>
                                                                              }

                                                                        </li>


                                                                        {' '}

                                                                  </>

                                                            </>

                                                      )

                                                })}


                                                <li className="c-footer-nav__item">

                                                      <a href="#contact" className="c-footer-nav__link js-show-contact  ">{TranslateResolver(0)}</a>

                                                </li>

                                          </ul>

                                    </nav>

                              </div>
                        </div>

                        <div className="c-footer__middle">
                              <div className="c-footer__middle-decoration"></div>
                              <div className="c-footer__middle-wrapper o-wrapper">


                                    {$datosContacto && $datosContacto.primary.title &&
                                          <div className="c-footer__group">

                                                <div className="c-footer-contact">

                                                      <h2 className="u-hidden-for-seo">{$datosContacto.primary.title[0].text}</h2>

                                                      <RichText render={$datosContacto.primary.content} />
                                                </div>

                                                <div className="c-footer-social">
                                                      <h3 className="c-footer-social__title">{$redesSociales.primary.title ? $redesSociales.primary.title[0].text : ''}</h3>
                                                      <ul className="c-footer-social__list">

                                                            {$redesSociales.fields.map((field, i) => {
                                                                  return (
                                                                        <li key={i} className="c-footer-social__item">
                                                                              {field &&
                                                                                    <a href={field.link.url}
                                                                                          className={"c-footer-social__link c-footer-social__link--" + field.key}
                                                                                          title={field.title[0].text}
                                                                                          target="_blank"
                                                                                          rel="noreferrer"
                                                                                          rel="noopener"></a>
                                                                              }
                                                                        </li>
                                                                  )
                                                            })}

                                                      </ul>
                                                </div>
                                          </div>
                                    }

                                    {$partners && $partners.primary.title &&
                                          <div className="c-footer__group c-footer-partners">
                                                <h3 className="c-footer-partners__title">{$partners.primary.title[0].text}</h3>
                                                <ul className="c-footer-partners__list">

                                                      {$partners.fields.map((field, i) => {

                                                            return (

                                                                  <li key={i} className="c-footer-partners__item">
                                                                        <img src={field.image.url} width={field.image.dimensions.width / 2} className="c-footer-partners__logo" alt={field.title[0].text} title={field.title[0].text} />
                                                                  </li>

                                                            )
                                                      })}


                                                </ul>
                                          </div>
                                    }

                              </div>
                        </div>

                        <div className="c-footer__bottom o-wrapper">

                              <p className="c-footer__copyright">IMÁN &copy; {new Date().getFullYear()}. {data.rights}</p>
                              {' '}
                              <p className="c-footer__dev">{data.bloque} <a href="https://www.bloque.com.uy" rel="noreferrer" target="_blank" className="c-footer__dev-link" title="Bloque"><img src={'../../images/bloque.svg'} width="44" alt="Bloque" /></a></p>

                        </div>
                  </footer>

            </>
      )
}



Footer.propTypes = {
      navData: PropTypes.object,
      data: PropTypes.object,
      actualLang: PropTypes.string,
      showCta: PropTypes.bool
}


export default Footer;
