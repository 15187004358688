import { useLocation } from "@reach/router"

export const LangResolver = () => {
     
      const { pathname } = useLocation()

      var value = '';


      if (pathname.split('/')[1] == "en") {

            value = "/en/"


      } else {

            value = "/"

      }

      return value
}

export default LangResolver;

