import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { RichText } from "prismic-reactjs";
import { TranslateResolver } from "../utils/translateResolver";
import { LangResolver } from '../utils/langResolver'
import { ChangeLangResolver } from '../utils/changeLangResolver'
import { useLocation } from "@reach/router"


import {
   Formik,
   Form,
   Field,
   ErrorMessage
} from 'formik'


const Header = ({ data, actualLang, changeLang, title, footerData }) => {

   var lang = LangResolver();

   const campoError = TranslateResolver(5);
   const emailError = TranslateResolver(6);

   const { pathname } = useLocation()


   const $datosContacto = footerData.body[1];
   const $redesSociales = footerData.body[2];
   const $formContacto = footerData.body[5];


   const encode = (data) => {
      return Object.keys(data)
         .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
         .join("&");
   }


   //Form

   var loading = false;
   var messageSent = false;
   var messageResult = false;


   return (

      <>



         <header className="c-header js-header" data-visible-nav="false" data-visible-contact="false">
            <div className="c-header__holder">
               <h1 className="c-header__logo c-logo">



                  {(pathname == "/") &&

                     <button className="c-logo__link js-goTo-intro">
                        <span className="u-hidden-for-seo">{title}</span>
                        <svg className="c-logo__svg is-dark js-logo__svg" enableBackground="new 0 0 158 48" viewBox="0 0 158 48" xmlns="http://www.w3.org/2000/svg"><path fill="#FFFFFF" d="m.5.8h12.3v46.5h-12.3z" /><path fill="#FFFFFF" d="m157 37.8c-.4-.8-.8-1.5-1.5-2-.6-.5-1.3-.9-2.2-1.2-.8-.3-1.7-.4-2.6-.4v-33.4h-12.3v19c0 1.3 0 2.6.1 4 0 1.4.1 2.6.2 3.7l-18.2-26.7h-11.6v18.6l-2.5-6.4-18.8 15.3 7.6-12h-6.4l9.1-15.5h-9.5l-16.5 41.8v-41.8h-11.6l-14.1 21.7-14.2-21.7h-11.6v46.5h12.3v-24.8l10 15.3h7l10-15.3v24.7h10.5 1.8 10.8l2.7-7.3h19.3l2.7 7.3h1.7 11 1.3v-19c0-1.3 0-2.6-.1-3.9 0-1.4-.1-2.6-.2-3.7l18.2 26.6h11.3.3c.9 0 1.8-.1 2.6-.4s1.5-.7 2.2-1.2c.6-.5 1.1-1.2 1.5-2s.5-1.8.5-3c-.3-1-.5-2-.8-2.8" /><path fill="#FFFFFF" d="m103.8 6.3-4.1 4.9h6z" /></svg>
                     </button>


                  }


                  {(pathname != "/") &&

                     <Link to={"/"} className="c-logo__link">
                        <span className="u-hidden-for-seo">{title}</span>
                        <svg className="c-logo__svg is-dark js-logo__svg" enableBackground="new 0 0 158 48" viewBox="0 0 158 48" xmlns="http://www.w3.org/2000/svg"><path fill="#FFFFFF" d="m.5.8h12.3v46.5h-12.3z" /><path fill="#FFFFFF" d="m157 37.8c-.4-.8-.8-1.5-1.5-2-.6-.5-1.3-.9-2.2-1.2-.8-.3-1.7-.4-2.6-.4v-33.4h-12.3v19c0 1.3 0 2.6.1 4 0 1.4.1 2.6.2 3.7l-18.2-26.7h-11.6v18.6l-2.5-6.4-18.8 15.3 7.6-12h-6.4l9.1-15.5h-9.5l-16.5 41.8v-41.8h-11.6l-14.1 21.7-14.2-21.7h-11.6v46.5h12.3v-24.8l10 15.3h7l10-15.3v24.7h10.5 1.8 10.8l2.7-7.3h19.3l2.7 7.3h1.7 11 1.3v-19c0-1.3 0-2.6-.1-3.9 0-1.4-.1-2.6-.2-3.7l18.2 26.6h11.3.3c.9 0 1.8-.1 2.6-.4s1.5-.7 2.2-1.2c.6-.5 1.1-1.2 1.5-2s.5-1.8.5-3c-.3-1-.5-2-.8-2.8" /><path fill="#FFFFFF" d="m103.8 6.3-4.1 4.9h6z" /></svg>
                     </Link>


                  }


               </h1>
               <button type="button" className="c-header__btn c-nav-btn js-nav-btn" title="Ver/Ocultar Menú"><span /></button>
            </div> {/* .c-header__holder */}


            {/* HEADER POPUP */}
            <div className="c-header__popup c-header-popup js-header-popup">
               <div className="c-header-popup__bg" />
               <div className="c-header-popup__holder o-align-middle">{/*
              */}<div className="o-align-middle__aligner">


                     {/* NAV */}
                     <div className="c-header-popup__section c-nav js-nav" tabIndex={0}>
                        <div className="c-nav__wrapper o-wrapper">
                           <nav className="c-nav__nav">
                              <h2 className="u-hidden-for-seo">{TranslateResolver(1)}</h2>
                              <ul className="c-nav__list">

                                 {data.links.map((field, i) => {
                                    return (

                                       <>

                                          <li key={field.link._meta.uid} className="c-nav__item">


                                             {(pathname + "home" == "/" + field.link._meta.uid || pathname + "home" == "/" + field.link._meta.uid + "/") &&
                                                <button className="c-nav__link js-goTo-intro is-active">{field.label}</button>
                                             }

                                             {(pathname == "/" + field.link._meta.uid || pathname == "/" + field.link._meta.uid + "/") &&
                                                <button className="c-nav__link js-goTo-intro is-active">{field.label}</button>
                                             }

                                             {field && field.link._meta.uid && field.link._meta.uid == "home" && (pathname + "home" != "/" + field.link._meta.uid && pathname + "home" != "/" + field.link._meta.uid + "/") &&

                                                <Link to={`${lang}`} className="c-nav__link">{field.label}</Link>
                                             }

                                             {field && field.link._meta.uid && field.link._meta.uid != "home" && (pathname != "/" + field.link._meta.uid && pathname != "/" + field.link._meta.uid + "/") &&

                                                <Link to={`${lang}${field.link._meta.uid}`} className="c-nav__link">{field.label}</Link>
                                             }

                                          </li>

                                       </>

                                    )

                                 })}


                                 <li className="c-nav__item">
                                    <a href="#contact" className="c-nav__btn c-rounded-btn js-show-contact"><span>{TranslateResolver(3)}</span></a>
                                 </li>


                              </ul>
                           </nav>



                           {$datosContacto && $datosContacto.primary.title &&
                              <div className="c-footer__group">

                                 <div className="c-footer-contact">
                                    <RichText render={$datosContacto.primary.content} />
                                 </div>

                                 <div className="c-footer-social">
                                    <ul className="c-footer-social__list">

                                       {$redesSociales.fields.map((field, i) => {
                                          return (
                                             <li key={i} className="c-footer-social__item">
                                                {field &&
                                                   <a href={field.link.url}
                                                      className={"c-footer-social__link c-footer-social__link--" + field.key}
                                                      title={field.title[0].text}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      rel="noopener"></a>
                                                }
                                             </li>
                                          )
                                       })}

                                    </ul>
                                 </div>
                              </div>
                           }

                        </div> {/* .c-nav__wrapper */}
                     </div> {/* .c-nav */}




                     {/* CONTACT */}
                     <section className="c-header-popup__section c-contact js-contact" tabIndex={0}>
                        <div className="c-contact__decoration" />
                        <div className="c-contact__wrapper o-wrapper">

                           <h2 className="c-contact__title"><RichText render={$formContacto.primary.title}></RichText></h2>


                           <Formik
                              initialValues={{
                                 nombre: '',
                                 apellido: '',
                                 email: '',
                                 telefono: '',
                                 empresa: '',
                                 mensaje: ''
                              }}
                              onSubmit={
                                 (values, actions) => {

                                    loading = true;

                                    fetch("/", {
                                       method: "POST",
                                       headers: { "Content-Type": "application/x-www-form-urlencoded" },
                                       body: encode({ "form-name": "contacto-proyecto", ...values })
                                    })
                                       .then(() => {
                                          loading = false;
                                          messageSent = true;
                                          messageResult = true;


                                          var tracker = window.ga.getAll()[0];
                                          tracker.send('event', 'BotonEnviar', 'click', 'Contacto');

                                          actions.resetForm()
                                       })
                                       .catch(() => {
                                          loading = false;
                                          messageSent = true;
                                          messageResult = false;
                                       })
                                       .finally(() => {

                                          actions.setSubmitting(false)
                                          messageSent = false;

                                       })
                                 }
                              }
                              validate={values => {
                                 const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                                 const errors = {};

                                 if (!values.nombre) {
                                    errors.nombre = campoError
                                 }
                                 if (!values.apellido) {
                                    errors.apellido = campoError
                                 }
                                 if (!values.email || !emailRegex.test(values.email)) {
                                    errors.email = emailError
                                 }

                                 return errors;
                              }}
                           >
                              {() => (
                                 <Form key="contacto-proyecto" name="contacto-proyecto" method="POST" data-netlify="true"  netlify-honeypot="bot-field" className="c-contact__form c-form">

                                    <p className="u-hidden"> 
                                       <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                                    </p>


                                    <input type="hidden" name="form-name" value="contacto-proyecto" />


                                    <div className="c-form__row">

                                       {$formContacto.fields.filter(item => item.type != "textarea").map((field, i) => {
                                          return (
                                             <div key={i} className={`c-form__col c-contact__transition ${field.name == "empresa" && 'c-form__col--full'}`}>
                                                <div className="c-form__input-holder">
                                                   <label className="c-form__label">
                                                      <span className={`c-form__text ${field.required ? 'is-required' : ''}`}>{field.label}</span>

                                                      <Field name={field.name} type={field.type} className="c-form__input" placeholder={field.placeholder} />

                                                   </label>
                                                   <label for={field.name} className="c-form__error"><ErrorMessage name={field.name} /></label>

                                                </div>
                                             </div>
                                          )
                                       })}


                                       {$formContacto.fields.filter(item => item.type == "textarea").map((field, i) => {
                                          return (

                                             <div key={i} className="c-form__col c-contact__transition c-form__col--full">
                                                <div className="c-form__input-holder c-contact__transition">
                                                   <label className="c-form__label">
                                                      <span className={`c-form__text ${field.required ? 'is-required' : ''}`}>{field.label}</span>
                                                      <Field component="textarea" name={field.name} type={field.type} className="c-form__input" placeholder={field.placeholder} />
                                                   </label>

                                                   <label for={field.name} className="c-form__error"><ErrorMessage name={field.name} /></label>

                                                </div>
                                             </div>

                                          )
                                       })}

                                       <div data-netlify-recaptcha="true"></div>

                                       <div className="c-form__col c-form__col--full c-contact__transition">
                                          <div className="c-form__bottom c-contact__transition">

                                             {!loading && $formContacto.primary && <button type="submit" className="c-form__submit c-rounded-btn"><span>{$formContacto.primary.button_text}</span></button>}

                                             {loading && <button type="submit" className="c-form__submit c-rounded-btn is-loading" disabled><span>{TranslateResolver(13)}</span></button>}

                                             {!loading && messageSent &&
                                               
                                               <div className="c-form__response o-align-middle" data-success={messageResult}>

                                                   {messageResult &&
                                                      <span className="o-align-middle__aligner">{TranslateResolver(14)}</span>}

                                                   {!messageResult &&
                                                      <span className="o-align-middle__aligner">{TranslateResolver(12)}</span>}

                                                </div>
                                             }

                                          </div> {/* .c-form__bottom */}
                                       </div>


                                    </div> {/* .c-form__row */}

                                 </Form>
                              )}


                           </Formik>


                        </div> {/* .c-contact__wrapper */}
                     </section> {/* .c-contact */}



                  </div> {/* .o-align-middle__aligner */}
               </div> {/* .c-header-popup__holder */}
            </div> {/* .c-nav */}


            {/* LANG 
            <div className="c-lang">

               <a href={`${ChangeLangResolver()}`} className="c-lang__btn" data-lang={TranslateResolver(9)} title={TranslateResolver(8)}>
                  <span className="u-hidden-for-seo">{TranslateResolver(8)}</span>
                  <span className="c-lang__flag" />
               </a> 
            </div> 

         */}


         </header> {/* .c-header */}



      </>
   )
}

Header.propTypes = {
   data: PropTypes.object,
   actualLang: PropTypes.string,
   changeLang: PropTypes.string,
   title: PropTypes.string,
   footerData: PropTypes.object,
}


export default Header
