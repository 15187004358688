import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer";
import Header from "./header"
import Helmet from "react-helmet"


import "../../static/css/reset.css"
import "../../static/css/screen.css"

const Layout = ({ children, data, actualLang, changeLang, showCta }) => {



  return (

    <>

      <noscript
        dangerouslySetInnerHTML={{
          __html: `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NW6RGNP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
` }}/>

      <Helmet>
        
      </Helmet>


      {data &&
        <>

          {/*actualLang={actualLang} changeLang={changeLang}  */}
          <Header data={data.$header} title={data.$metadata.site_name} footerData={data.$footer} ></Header>

          <div className="o-viewport js-viewport">
            {children}

            {/*actualLang={actualLang}  */}
            <Footer navData={data.$header} data={data.$footer} showCta={showCta} />
          </div>



        </>
      }
    </>

  )

}

Layout.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object,
  actualLang: PropTypes.string,
  changeLang: PropTypes.string,
  showCta: PropTypes.bool

}

export default Layout
