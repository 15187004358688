import { useLocation } from "@reach/router"

export const TranslateResolver = ( item ) => {
      
      const { pathname } = useLocation()

      const translations = [
            ["Contacto", "Contact"],
            ["Navegación", "Navigation"],
            ["Ver más", "View more"],
            ["Empezá ahora", "Start now"],
            ["casos-de-exito", "cases"],
            ["El campo es requerido", "Field is required"],
            ["El email debe ser válido", "Email must be valid"],
            ["Ver caso de éxito", "View case"],
            ["Go to English version", "Ir a versión en español"],
            ["en", "es"],
            ["Suscribiendo...", "Subscribing..."],
            ["¡Te has suscripto correctamente!", "You have successfully subscribed!"],
            ["Ha ocurrido un error", "An error has occurred"],
            ["Enviando...", "Sending..."],
            ["Su mensaje ha sido enviado!", "Your message has been sent!"],
      ]

      var value = '';


      if (pathname.split('/')[1] == "en") {

            value = translations[item].splice(',')[1]

      } else {

            value = translations[item].splice(',')[0]

      }


      return value
}

export default TranslateResolver;
